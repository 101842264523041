import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { RebrandlyLogo, RightIcon, TelegramCardIcon } from "../atoms/Icons"


const Rebrandly = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.rebrandly}>
      <div className={styles.texts}>
        <div className={styles.title}>{t("rebrandly")}</div>
        <div className={styles.description}>{t("thisIsOurBackupLink")}</div>
      </div>

      <div className={styles.container}>
        <div className={styles.logo}>
          <RebrandlyLogo />
        </div>
        <a href="https://rebrand.ly/bitily" className={styles.link} target="_blank">https://rebrand.ly/bitily <RightIcon /></a>
      </div>
    </div>
  )
}

export default Rebrandly
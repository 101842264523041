import React from 'react'
import classNames from 'classnames'

import * as styles from './styles.module.scss'
import { ArrowRightIcon, CloseIcon } from '../atoms/Icons'
import { useTranslation } from 'react-i18next'

export interface IAccordionItemProps {
  id: number
  isExpanded?: boolean
  toggleExpanded: () => void
  title: string
  description: string
}

const AccordionItem = (props: React.PropsWithChildren<IAccordionItemProps>) => {
  const { t } = useTranslation()
  const {
    isExpanded, toggleExpanded, title, description,
  } = props

  return (
    <div className={classNames(styles.accordionItem, { [styles.expanded]: isExpanded })}>
      <button className={styles.accordionItemButton} onClick={() => toggleExpanded()}>
        {isExpanded ? <CloseIcon /> : <ArrowRightIcon />}

        <div className={styles.accordionItemTitle}>{t(title)}</div>
      </button>

      <div className={styles.accordionContent}>
        <div className={classNames(styles.accordionItemDescription)}>{t(description)}</div>
      </div>
    </div>
  )
}

export default AccordionItem

import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { CENOLogo, LanternLogo, OnionLogo, PsiphonLogo, TorLogo } from "../atoms/Icons"
import Card from "../Card"
import useDeviceDetect from "../../hooks/useDeviceDetect"
import classNames from "classnames"


const SecureBrowsers = () => {
  const { t } = useTranslation()
  const { device } = useDeviceDetect()

  const cards = device === "Mac" || device === "IOS" ? [
    {
      logo: <OnionLogo />,
      name: 'Onion',
      link: "https://onionbrowser.com/",
    },
    {
      logo: <PsiphonLogo />,
      name: 'Psiphon',
      link: "https://psiphon.ca/en/download.html",
    },
    {
      logo: <LanternLogo />,
      name: 'Lantern',
      link: "https://lantern.io/download",
    },
  ] : [
    {
      logo: <TorLogo />,
      name: 'Tor',
      link: "https://www.torproject.org/download/",
    },
    {
      logo: <PsiphonLogo />,
      name: 'Psiphon',
      link: "https://psiphon.ca/en/download.html",
    },
    {
      logo: <LanternLogo />,
      name: 'Lantern',
      link: "https://lantern.io/download",
    },
    {
      logo: <CENOLogo />,
      name: 'CENO',
      link: "https://censorship.no/index.html",
    },
  ]

  return (
    <div className={styles.secureBrowsers}>
      <div className={styles.texts}>
        <div className={styles.title}>{t("secureBrowsers")}</div>
        <div className={styles.description}>{t("installSecureBrowsers")}</div>
      </div>

      <div className={styles.container}>
        <div className={classNames(styles.cards, { [styles.mac]: device === "Mac" || device === "IOS" })}>
          {cards.map((card) => (
            <Card key={card.name} { ...card } />
          ))}
        </div>
      </div>
    </div>
  )
}

export default SecureBrowsers
import React from "react";
import { UAParser } from "ua-parser-js";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = React.useState(false);
  const [device, setDevice] = React.useState<"IOS" | "Android" | "Mac" | "Windows">("Windows");
  const [browser, setBrowser] = React.useState<"Chrome" | "Safari">("Chrome");

  React.useEffect(() => {
    if (typeof window !== "undefined") {
      const parser = new UAParser(navigator.userAgent)
      const os = parser?.getOS()
      const currentBrowser = parser?.getBrowser()

      switch (os.name) {
        case "iOS":
          setDevice("IOS")
          break
        case "Android[-x86]":
          setDevice("Android")
          break
        case "Mac OS":
          setDevice("Mac")
          break
        default:
          setDevice("Windows")
          break
      }
      
      switch (currentBrowser.name) {
        case "Safari":
          setBrowser("Safari")
          break
        default:
          setBrowser("Chrome")
          break
      }

      setMobile(window.innerWidth < 768)
    }
  }, [])

  return { isMobile, device, browser }
}
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { RightIcon, TelegramCardIcon } from "../atoms/Icons"


const TelegramBot = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.telegramBot}>
      <div className={styles.title}>{t("telegramBot")}</div>

      <div className={styles.container}>
        <div className={styles.logo}>
          <TelegramCardIcon />
        </div>
        <div className={styles.content}>
          <div className={styles.nick}>@bilbet_mirror_bot</div>
          <div className={styles.description}>{t("signUpTelegram")}</div>
          <a href="https://t.me/bilbet_mirror_bot" className={styles.link} target="_blank">{t("subscribeTelegram")} <RightIcon /></a>
        </div>
      </div>
    </div>
  )
}

export default TelegramBot
import classNames from "classnames"
import React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  className?: string
}

export const Divider = ({ className }: IProps) => {
  return (
    <svg
      className={classNames(styles.divider, className)}
      xmlns="http://www.w3.org/2000/svg"
      width="800"
      height="3"
      viewBox="0 0 800 3"
      fill="none"
    >
      <path d="M0 1L800 2" stroke="url(#paint0_linear_22808_12156)" stroke-opacity="0.4"/>
      <defs>
        <linearGradient id="paint0_linear_22808_12156" x1="0" y1="1" x2="800" y2="1.99403" gradientUnits="userSpaceOnUse">
          <stop stop-opacity="0"/>
          <stop offset="0.536458" stop-color="white"/>
          <stop offset="1" stop-opacity="0"/>
        </linearGradient>
      </defs>
    </svg>
  )
}
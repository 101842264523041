// extracted by mini-css-extract-plugin
export var active = "styles-module--active--3c2da";
export var bilbetBtn = "styles-module--bilbetBtn--faa3a";
export var bold = "styles-module--bold--760b3";
export var browserName = "styles-module--browserName--94428";
export var container = "styles-module--container--9b429";
export var description = "styles-module--description--00dc8";
export var eternalLink = "styles-module--eternalLink--2cad1";
export var flex = "styles-module--flex--04bf1";
export var hotkey = "styles-module--hotkey--fd28a";
export var insructionPoints = "styles-module--insructionPoints--9b733";
export var instructionLast = "styles-module--instructionLast--9224e";
export var instructions = "styles-module--instructions--43d96";
export var point = "styles-module--point--fc5cb";
export var positionLeft = "styles-module--positionLeft--7c009";
export var tab = "styles-module--tab--e68dd";
export var tabContent = "styles-module--tabContent--c34b9";
export var tabs = "styles-module--tabs--f5a9d";
export var text = "styles-module--text--b233c";
export var texts = "styles-module--texts--97060";
export var title = "styles-module--title--6e970";
export var video = "styles-module--video--bbdbe";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { BilbetBtnIcon, ChromeIcon, ChromeSettingsIcon, FirefoxIcon, OperaIcon, RightIcon, SafaryIcon, YandexBrowserIcon } from "../atoms/Icons"
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"


const cards = [
  {
    icon: <ChromeIcon />,
    name: "chrome",
    text: "chromeText",
    storeLink: "",
    downloadLink: "",
  },
  {
    icon: <FirefoxIcon />,
    name: "firefox",
    text: "firefoxText",
    storeLink: "",
    downloadLink: "",
  },
  {
    icon: <OperaIcon />,
    name: "opera",
    text: "operaText",
    storeLink: "",
    downloadLink: "",
  },
  {
    icon: <YandexBrowserIcon />,
    name: "yandexBrowser",
    text: "yandexBrowserText",
    storeLink: "",
    downloadLink: "",
  },
  {
    icon: <SafaryIcon />,
    name: "safari",
    text: "safariText",
    storeLink: "",
    downloadLink: "",
  },
]

const Extension = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.extension}>
      <div className={styles.texts}>
        <div className={styles.title}>{t("extension")}</div>
        <div className={styles.description}>{t("installTheBrowser")}</div>
      </div>

      <div className={styles.container}>
        <div className={styles.cards}>
          {cards.map(card => (
            <div className={styles.card} key={card.name}>
              <div className={styles.icon}>
                {card.icon}
              </div>
              <div className={styles.content}>
                <div className={styles.name}>{t(card.name)}</div>
                <div className={styles.description}>
                <Trans i18nKey={card.text} components={{ a: <a className={styles.link} href={card.storeLink} /> }} /></div>
                <a href={card.downloadLink} className={styles.download} target="_blank">{t("download")}<RightIcon /></a>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.imageContainer}>
          <StaticImage className={styles.image} placeholder="blurred" quality={100} src="../../images/extension-img.png" alt="extension-img" />
        </div>
      </div>
    </div>
  )
}

export default Extension
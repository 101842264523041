// extracted by mini-css-extract-plugin
export var active = "styles-module--active--0d6ee";
export var androidApp = "styles-module--androidApp--e9bbc";
export var carousel = "styles-module--carousel--1f23f";
export var carouselBtn = "styles-module--carouselBtn--aa10c";
export var carouselItem = "styles-module--carouselItem--a1279";
export var carouselTexts = "styles-module--carouselTexts--17a57";
export var disabled = "styles-module--disabled--b5b62";
export var dot = "styles-module--dot--1b0ce";
export var dotActive = "styles-module--dotActive--9d9c3";
export var dotNotActive = "styles-module--dotNotActive--9441d";
export var dots = "styles-module--dots--e3914";
export var downloadBtn = "styles-module--downloadBtn--8f612";
export var frame = "styles-module--frame--8ecbd";
export var frame1 = "styles-module--frame1--f24b3";
export var frame1Bottom = "styles-module--frame1Bottom--94a2a";
export var frame1Top = "styles-module--frame1Top--478ef";
export var frame2 = "styles-module--frame2--1b38f";
export var frame2Block = "styles-module--frame2Block--bc9c9";
export var frame2Blocks = "styles-module--frame2Blocks--10b32";
export var frame2Bottom = "styles-module--frame2Bottom--c95c7";
export var frame2Top = "styles-module--frame2Top--659de";
export var frame2hand = "styles-module--frame2hand--52e77";
export var frame2link = "styles-module--frame2link--6da1f";
export var frame2link2 = "styles-module--frame2link2--965ab";
export var frame3 = "styles-module--frame3--6f2fc";
export var frame3Setting = "styles-module--frame3Setting--2085f";
export var frame3hand = "styles-module--frame3hand--49d4e";
export var frame3link = "styles-module--frame3link--98c8e";
export var frame4 = "styles-module--frame4--e4ad7";
export var frame4Btn = "styles-module--frame4Btn--0bd2d";
export var frame4Description = "styles-module--frame4Description--5dcf2";
export var frame4Texts = "styles-module--frame4Texts--9244c";
export var frame4Title = "styles-module--frame4Title--15a09";
export var frame4hand = "styles-module--frame4hand--89ed2";
export var frame5 = "styles-module--frame5--02169";
export var frame5Block = "styles-module--frame5Block--d3a84";
export var frame5Title = "styles-module--frame5Title--6eb3d";
export var frame5Top = "styles-module--frame5Top--34d9d";
export var frame5hand = "styles-module--frame5hand--05cc7";
export var hand1 = "styles-module--hand1--25ae8";
export var hand2 = "styles-module--hand2--cb57a";
export var header = "styles-module--header--24446";
export var step1Btn = "styles-module--step1Btn--3704e";
export var step1Controls = "styles-module--step1Controls--abcdd";
export var step1ControlsLeft = "styles-module--step1ControlsLeft--55641";
export var step1ControlsRight = "styles-module--step1ControlsRight--50405";
export var step1Description = "styles-module--step1Description--0bd0e";
export var step1Texts = "styles-module--step1Texts--aef7b";
export var step1Title = "styles-module--step1Title--fc698";
export var step5Controls = "styles-module--step5Controls--9f5c3";
export var step5ControlsLeft = "styles-module--step5ControlsLeft--2216a";
export var step5ControlsRight = "styles-module--step5ControlsRight--9dad3";
export var stepDescription = "styles-module--stepDescription--34c46";
export var stepTitle = "styles-module--stepTitle--ad631";
export var title = "styles-module--title--08066";
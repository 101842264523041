import { graphql } from "gatsby";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"
import Footer from "../components/Footer";
import Header from "../components/Header";
import SEO from "../components/SEO";
import * as styles from './styles.module.scss'
import AndroidApp from "../components/AndroidApp";
import Tabs from "../components/atoms/Tabs";
import { Divider } from "../components/atoms/Divider";
import CurrentMirror from "../components/CurrentMirror";
import FAQ from "../components/FAQ";
import SecureBrowsers from "../components/SecureBrowsers";
import VPN from "../components/VPN";
import TelegramBot from "../components/TelegramBot";
import Rebrandly from "../components/Rebrandly";

import '../styles/globals.scss'
import EternalLink from "../components/EternalLink";
import useDeviceDetect from "../hooks/useDeviceDetect";
import Extension from "../components/Extension";

const IndexPage = () => {
  const { t } = useTranslation()
  const { isMobile } = useDeviceDetect()

  const tabs = isMobile ? [
    "androidApp",
    "currentMirror",
    "telegramBot",
    "rebrandly",
    "VPN",
    "secureBrowsers",
    "faq",
  ] : [
    "androidApp",
    "currentMirror",
    "extension",
    "telegramBot",
    "rebrandly",
    "eternalLink",
    "VPN",
    "secureBrowsers",
    "faq",
  ]

  return (
    <main>
      <SEO
        title={t("metaTitle")}
        description={t("description")}
      />
      <Header tabs={tabs} />
      <section className={styles.firstBlock}>
        <div className={styles.bgElemTop}>
          <svg width="611" height="686" viewBox="0 0 611 686" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.52">
              <path d="M117.55 36.9873C217.985 59.0338 318.472 80.842 418.902 102.908C453.309 110.737 483.901 133.358 501.693 163.812C518.935 192.691 524.292 228.355 516.258 261.032C509.401 290.325 491.885 316.79 467.942 334.964C489.533 352.601 507.094 375.162 518.508 400.624C534.187 435.132 538.294 474.706 530.104 511.717C522.044 549.277 501.365 583.935 472.139 608.861C440.394 636.274 398.695 651.921 356.73 651.928C332.179 652.41 308.47 645.421 284.664 640.49C170.721 615.617 56.8143 590.669 -57.1424 565.855C-57.2355 565.71 -57.426 565.44 -57.5192 565.295C42.1314 521.51 141.855 477.866 241.51 434.062C275.378 471.877 308.986 509.928 342.826 547.779C337.987 497.935 332.917 448.102 328.054 398.274C327.18 395.419 330.94 395.159 332.57 394.08C377.441 374.486 422.327 354.916 467.056 335.02C417.567 324.042 367.996 313.441 318.508 302.462C313.462 252.136 308.628 201.793 303.455 151.481C277.309 194.784 252.283 238.77 226.574 282.356C120.437 259.272 14.3886 235.874 -91.7444 212.77C-85.8675 197.202 -78.9685 182.003 -71.1969 167.286C-0.0660444 197.286 70.9445 227.55 142.075 257.55C151.986 212.28 161.898 167.011 171.729 121.724C163.619 119.515 155.593 116.638 148.544 111.957C123.962 96.5792 110.998 65.265 117.55 36.9873Z" fill="url(#paint0_linear_18091_199626)"/>
              <path d="M-106.012 257.928C-22.3698 275.703 61.1605 294.181 144.664 312.591C143.895 315.252 142.151 316.286 139.486 315.724C54.5581 314.022 -30.3949 312.626 -115.32 311.007C-114.374 301.726 -112.725 292.558 -111.556 283.306L-106.012 257.928Z" fill="url(#paint1_linear_18091_199626)"/>
              <path d="M-117.678 364.605C-29.1919 364.588 59.2884 362.885 147.772 363.264C149.364 367.877 143.919 366.708 141.54 367.79C56.7593 384.365 -28.1923 400.008 -112.984 416.54C-115.678 399.349 -117.252 381.987 -117.678 364.605Z" fill="url(#paint2_linear_18091_199626)"/>
              <path d="M143.384 418.275C146.577 417.849 150.173 416.055 153.092 418.503C148.719 422.042 143.242 423.591 138.211 425.862C64.8427 457.143 -8.54746 488.336 -81.9041 519.661C-89.1594 503.638 -95.4177 487.145 -100.518 470.303C-19.2094 453.052 62.1007 435.698 143.384 418.275Z" fill="url(#paint3_linear_18091_199626)"/>
            </g>
            <defs>
              <linearGradient id="paint0_linear_18091_199626" x1="282.374" y1="72.9971" x2="164.13" y2="614.22" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.08"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint1_linear_18091_199626" x1="19.3785" y1="285.019" x2="7.82308" y2="337.91" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.08"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint2_linear_18091_199626" x1="21.5512" y1="335.688" x2="-1.43721" y2="440.91" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.08"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
              <linearGradient id="paint3_linear_18091_199626" x1="37.5951" y1="392.644" x2="5.6652" y2="538.793" gradientUnits="userSpaceOnUse">
              <stop stop-color="white" stop-opacity="0.08"/>
              <stop offset="1" stop-color="white" stop-opacity="0"/>
              </linearGradient>
            </defs>
          </svg>
        </div>
        <div className={styles.container}>
          <div className={styles.content}>
            <h1>{t("noAccess")}</h1>
            <div className={styles.description}>
              {t("hereWeProvide")}
            </div>
            <div className={styles.tabs}>
              <div className={styles.tabsTitle}>{t("7methods")}</div>
              <Tabs tabs={tabs} />
            </div>
            <section id="androidApp">
              <AndroidApp />
              <Divider />
            </section>
            <section id="currentMirror">
              <CurrentMirror />
              <Divider />
            </section>
            {!isMobile && (
              <>
                <section id="extension">
                  <Extension />
                  <Divider />
                </section>
              </>
            )}
            <section id="telegramBot">
              <TelegramBot />
              <Divider />
            </section>
            <section id="rebrandly">
              <Rebrandly />
              <Divider />
            </section>
            {!isMobile && (
              <>
                <section id="eternalLink">
                  <EternalLink />
                  <Divider />
                </section>
              </>
            )}
            <section id="VPN">
              <VPN />
              <Divider />
            </section>
            <section id="secureBrowsers">
              <SecureBrowsers />
              <Divider />
            </section>
            <section id="faq">
              <FAQ />
            </section>
          </div>
        </div>
      </section>
      <Footer />
    </main>
  )
}

export default IndexPage

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
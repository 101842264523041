import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { AndroidIcon, ArrowLeftIcon, ArrowRightIcon, DownloadIcon, QuestionIcon, SwichIcon } from "../atoms/Icons"

import * as styles from './styles.module.scss'
import classNames from "classnames"
import { StaticImage } from "gatsby-plugin-image"

const steps = [
  { 
    id: 1,
    title: "1step",
    description: "clickDownload",
  },
  { 
    id: 2, 
    title: "2step",
    description: "openTheInstallUnknownApps",
  },
  { 
    id: 3, 
    title: "3step",
    description: "allowAppInstallation",
  },
  { 
    id: 4, 
    title: "4step",
    description: "selectTheDownloadedAppFile",
  },
  { 
    id: 5, 
    title: "5step",
    description: "tapInstall",
  },
  { 
    id: 6, 
    title: "6step",
    description: "appInstalled",
  },
]

const AndroidApp = () => {
  const { t } = useTranslation()

  const [currentStep, setCurrentStep] = React.useState(steps[0])

  const stepContent = (step: number) => {
    switch(step) {
      case 1: 
        return (
          <div className={classNames(styles.frame, styles.frame1)}>
            <div className={styles.frame1Top}>
              <div className={styles.step1Btn}>
                <AndroidIcon />{t("downloadAPK")}
                <StaticImage className={styles.hand1} placeholder="blurred" quality={100} src="../../images/handWhite.png" alt="handWhite" />
              </div>
            </div>
            <div className={styles.frame1Bottom}>
              <div className={styles.step1Texts}>
                <div className={styles.step1Title}>{t("downloadThisFile")}</div>
                <div className={styles.step1Description}>{t("filesOfThisType")}</div>
              </div>
              <div className={styles.step1Controls}>
                <div className={styles.step1ControlsLeft}>
                  {t("cancel")}
                </div>
                <div className={styles.step1ControlsRight}>
                  {t("load")}
                </div>
              </div>
              <StaticImage className={styles.hand2} placeholder="blurred" quality={100} src="../../images/handDark.png" alt="handDark" />
            </div>
          </div>
        )
      case 2: return (
        <div className={classNames(styles.frame, styles.frame2)}>
          <div className={styles.frame2Top}>
            <div className={styles.frame2link}><ArrowLeftIcon />{t("settings")}</div>
            <div className={styles.frame2Blocks}>
              <div className={styles.frame2Block} />
              <div className={styles.frame2Block} />
              <div className={styles.frame2Block} />
            </div>
          </div>
          <div className={styles.frame2Bottom}>
            <div className={styles.frame2link2}>
              {t("installUnknownApps")}
              <StaticImage className={styles.frame2hand} placeholder="blurred" quality={100} src="../../images/handWhite.png" alt="handWhite" />
            </div>
            <div className={styles.frame2Blocks}>
              <div className={styles.frame2Block} />
              <div className={styles.frame2Block} />
              <div className={styles.frame2Block} />
            </div>
          </div>
        </div>
      )
      case 3: return (
        <div className={classNames(styles.frame, styles.frame3)}>
          <div className={styles.frame3link}><ArrowLeftIcon />{t("installUnknownApps")}</div>
          <div className={styles.frame3Setting}>
            {t("allowAppInstallation")}
            <SwichIcon />
            <StaticImage className={styles.frame3hand} placeholder="blurred" quality={100} src="../../images/handWhite.png" alt="handWhite" />
          </div>
        </div>
      )
      case 4: return (
        <div className={classNames(styles.frame, styles.frame4)}>
          <div className={styles.frame4Btn}>
            <DownloadIcon />
            <div className={styles.frame4Texts}>
              <div className={styles.frame4Title}>bilBet.apk</div>
              <div className={styles.frame4Description}>{t("downloadComplete")}</div>
            </div>
            <StaticImage className={styles.frame4hand} placeholder="blurred" quality={100} src="../../images/handDark.png" alt="handDark" />
          </div>
        </div>
      )
      case 5: return (
        <div className={classNames(styles.frame, styles.frame5)}>
          <div className={styles.frame5Top}>
            <div className={styles.frame5Title}>BilBet</div>
            <div className={styles.frame5Block} />
          </div>
          <div className={styles.step5Controls}>
            <div className={styles.step5ControlsLeft}>
              {t("cancel")}
            </div>
            <div className={styles.step5ControlsRight}>
              {t("install")}
            </div>
            <StaticImage className={styles.frame5hand} placeholder="blurred" quality={100} src="../../images/handWhite.png" alt="handWhite" />
          </div>
        </div>
      )
      case 6: return (
        <div className={classNames(styles.frame, styles.frame5)}>
          <div className={styles.frame5Top}>
            <div className={styles.frame5Title}>BilBet</div>
            <div className={styles.frame5Block} />
          </div>
          <div className={styles.step5Controls}>
            <div className={styles.step5ControlsLeft}>
              {t("done")}
            </div>
            <div className={styles.step5ControlsRight}>
              {t("open")}
            </div>
            <StaticImage className={styles.frame5hand} placeholder="blurred" quality={100} src="../../images/handWhite.png" alt="handWhite" />
          </div>
        </div>
      )
      default: return
    }
  }

  return (
    <div className={styles.androidApp}>
      <div className={styles.title}>{t("androidApp")}</div>
      <a href="https://bilbet1.com/android/bilBet.apk" className={styles.downloadBtn} download><AndroidIcon />{t("downloadAPK")}</a>
      <div className={styles.carousel}>
        <div className={styles.header}>
          <QuestionIcon />{t("howToInstall")}
        </div>
        <div className={styles.dots}>
          {steps.map(step => (
            <div className={classNames(styles.dot, { [styles.active]: step.id === currentStep.id })} key={step.id}></div>
          ))}
        </div>
        <div className={styles.carouselTexts}>
          <div className={styles.stepTitle}>{t(currentStep.title)}</div>
          <div className={styles.stepDescription}>{t(currentStep.description)}</div>
        </div>
        <div className={styles.carouselItem}>
          <button
            type="button" 
            className={classNames(styles.carouselBtn, { [styles.disabled]: currentStep.id === 1 })} 
            onClick={() => setCurrentStep(steps.find(step => step.id === currentStep.id - 1) || steps[0])}
            disabled={currentStep.id === 1}
          >
            <ArrowLeftIcon />
          </button>
          {stepContent(currentStep.id)}
          <button
            type="button" 
            className={classNames(styles.carouselBtn, { [styles.disabled]: currentStep.id === 6 })} 
            onClick={() => setCurrentStep(steps.find(step => step.id === currentStep.id + 1) || steps[5])}
            disabled={currentStep.id === 6}
          >
            <ArrowRightIcon />
          </button>
        </div>
      </div>
    </div>
  )
}

export default AndroidApp
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { CENOLogo, HolaVPNLogo, LanternLogo, NordVPNLogo, OpenVPNLogo, PsiphonLogo, TorLogo } from "../atoms/Icons"
import Card from "../Card"


const cards = [
  {
    logo: <NordVPNLogo />,
    name: 'NordVPN',
    link: "https://nordvpn.com/ru/download/",
  },
  {
    logo: <OpenVPNLogo />,
    name: 'OpenVPN',
    link: "https://openvpn.net/client/",
  },
  {
    logo: <HolaVPNLogo />,
    name: 'hola!VPN',
    link: "https://hola.org/?flow=hola_browser&step=install&",
  },
]


const VPN = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.vpn}>
      <div className={styles.texts}>
        <div className={styles.title}>{t("VPN")}</div>
        <div className={styles.description}>{t("installVPNs")}</div>
      </div>

      <div className={styles.container}>
        <div className={styles.cards}>
          {cards.map((card) => (
            <Card key={card.name} { ...card } />
          ))}
        </div>
      </div>
    </div>
  )
}

export default VPN
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { RightIcon } from "../atoms/Icons"

export interface ICardProps {
  logo: JSX.Element
  name: string
  link: string
}

const Card = ({ logo, name, link }: ICardProps) => {
  const { t } = useTranslation()

  return (
    <div className={styles.card}>
      <div className={styles.logo}>{logo}</div>
      <div className={styles.name}>{name}</div>
      <a href={link} className={styles.download} target="_blank">{t("download")}<RightIcon /></a>
    </div>
  )
}

export default Card
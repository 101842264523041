import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import { BilbetBtnIcon, ChromeIcon, ChromeSettingsIcon, FirefoxIcon, MozillaSettingsIcon, OperaIcon, OperaSettingsIcon, SafaryIcon, YandexBrowserIcon } from "../atoms/Icons"
import classNames from "classnames"
import useOutsideAlerter from "../../hooks/useClickOutside"


const tabs = [
  {
    id: 1,
    icon: <ChromeIcon />,
    content: (
      <>
        <div className={styles.browserName}>Google Chrome</div>
        <div className={styles.insructionPoints}>
          <div className={classNames(styles.point, styles.flex)}>1. <Trans i18nKey="clickOnTheIcon" /> <ChromeSettingsIcon /></div>
          <div className={styles.point}>2. <Trans i18nKey="selectBookmarks" components={{ span: <span className={styles.bold} /> }} /></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="382" height="2" viewBox="0 0 382 2" fill="none">
          <path d="M1 1H381" stroke="black" stroke-opacity="0.05" stroke-linecap="round"/>
        </svg>
        <div className={styles.instructionLast}>
          <Trans i18nKey="useHotkey" />
          <div className={styles.hotkey}>Ctrl + Shift + B</div>
        </div>
      </>
    )
  },
  {
    id: 2,
    icon: <FirefoxIcon />,
    positionLeft: true,
    content: (
      <>
        <div className={styles.browserName}>Mozila Firefox</div>
        <div className={styles.insructionPoints}>
          <div className={classNames(styles.point, styles.flex)}>1. <Trans i18nKey="clickOnTheIcon" /> <MozillaSettingsIcon /></div>
          <div className={styles.point}>2. <Trans i18nKey="selectBookmarks" components={{ span: <span className={styles.bold} /> }} /></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="382" height="2" viewBox="0 0 382 2" fill="none">
          <path d="M1 1H381" stroke="black" stroke-opacity="0.05" stroke-linecap="round"/>
        </svg>
        <div className={styles.instructionLast}>
          <Trans i18nKey="useHotkey" />
          <div className={styles.hotkey}>Ctrl + Shift + B</div>
        </div>
      </>
    )
  },
  {
    id: 3,
    icon: <OperaIcon />,
    positionLeft: true,
    content: (
      <>
        <div className={styles.browserName}>Opera</div>
        <div className={styles.insructionPoints}>
          <div className={classNames(styles.point, styles.flex)}>1. <Trans i18nKey="clickOnTheIcon" /> <OperaSettingsIcon /></div>
          <div className={styles.point}>2. <Trans i18nKey="toogleTheSlider" components={{ span: <span className={styles.bold} /> }} /></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="382" height="2" viewBox="0 0 382 2" fill="none">
          <path d="M1 1H381" stroke="black" stroke-opacity="0.05" stroke-linecap="round"/>
        </svg>
        <div className={styles.instructionLast}>
          <Trans i18nKey="useHotkey" />
          <div className={styles.hotkey}>Ctrl + Shift + B</div>
        </div>
      </>
    )
  },
  {
    id: 4,
    icon: <YandexBrowserIcon />,
    positionLeft: true,
    content: (
      <>
        <div className={styles.browserName}>Yandex Browser</div>
        <div className={styles.insructionPoints}>
          <div className={classNames(styles.point, styles.flex)}>1. <Trans i18nKey="clickOnTheIcon" /> <MozillaSettingsIcon /></div>
          <div className={styles.point}>2. <Trans i18nKey="selectSettings" components={{ span: <span className={styles.bold} /> }} /></div>
          <div className={styles.point}>3. <Trans i18nKey="selectInterface" components={{ span: <span className={styles.bold} /> }} /></div>
          <div className={styles.point}>4. <Trans i18nKey="findBookmarksBar" components={{ span: <span className={styles.bold} /> }} /></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="382" height="2" viewBox="0 0 382 2" fill="none">
          <path d="M1 1H381" stroke="black" stroke-opacity="0.05" stroke-linecap="round"/>
        </svg>
        <div className={styles.instructionLast}>
          <Trans i18nKey="useHotkey" />
          <div className={styles.hotkey}>Ctrl + Shift + B</div>
        </div>
      </>
    )
  },
  {
    id: 5,
    icon: <SafaryIcon />,
    positionLeft: true,
    content: (
      <>
        <div className={styles.browserName}>Safari</div>
        <div className={styles.insructionPoints}>
          <div className={styles.point}><Trans i18nKey="selectView" components={{ span: <span className={styles.bold} /> }} /></div>
        </div>
        <svg xmlns="http://www.w3.org/2000/svg" width="382" height="2" viewBox="0 0 382 2" fill="none">
          <path d="M1 1H381" stroke="black" stroke-opacity="0.05" stroke-linecap="round"/>
        </svg>
        <div className={styles.instructionLast}>
          <Trans i18nKey="useHotkey" />
          <div className={styles.hotkey}>Ctrl + Shift + B</div>
        </div>
      </>
    )
  }
]

const Tab = ({ tab, activeTab, setActiveTab }: { tab: any, activeTab: number, setActiveTab: (id: number) => void }) => {
  const wrapperRef = React.useRef(null)
  const btnRef = React.useRef(null)
  useOutsideAlerter(wrapperRef, btnRef, () => setActiveTab(0))

  return (
    <div className={classNames(styles.tab, { [styles.active]: tab.id === activeTab })} key={tab.id} onClick={() => setActiveTab(tab.id)} ref={btnRef}>
      {tab.icon}
      {tab.id === activeTab && (
        <div className={classNames(styles.tabContent, { [styles.positionLeft]: tab?.positionLeft })} ref={wrapperRef}>{tab.content}</div>
      )}
    </div>
  )
}

const EternalLink = () => {
  const { t } = useTranslation()
  const [activeTab, setActiveTab] = React.useState<number>(0)

  return (
    <div className={styles.eternalLink}>
      <div className={styles.texts}>
        <div className={styles.title}>{t("eternalLink")}</div>
        <div className={styles.description}>{t("holdTheButton")}</div>
      </div>

      <div className={styles.container}>
        <div className={styles.bilbetBtn}>
          <a href="https://bilbet1.com" target="_blank"><BilbetBtnIcon /></a>
        </div>
        <video className={styles.video} height="456" playsInline autoPlay muted loop>
          <source src="../../../video/eternal-link-video.mp4" type="video/mp4" />
        </video>
        <div className={styles.instructions}>
          <div className={styles.text}>{t("cantSeeTheBookmaker")}</div>

          <div className={styles.tabs}>
            {tabs.map(tab => <Tab key={tab.id} tab={tab} activeTab={activeTab} setActiveTab={setActiveTab} />)}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EternalLink
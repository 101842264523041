import * as React from 'react'
import classNames from 'classnames'
import { navigate } from "gatsby"

import * as styles from './styles.module.scss'

interface LogoProps {
  className?: string
}

const Logo = ({ className }: LogoProps) => {
  return (
    <svg
      onClick={() => navigate("/")}
      className={classNames(styles.logo, className)}
      width="138" height="44" viewBox="0 0 138 44" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1211 9.15527e-05H22.3543C26.0494 0.130365 29.7136 1.1858 32.8766 3.10899C36.4746 5.27506 39.4201 8.50761 41.2362 12.2955C43.0115 15.9652 43.7004 20.1439 43.2301 24.1912C42.858 27.4646 41.7209 30.6475 39.9357 33.4163C38.0788 36.3088 35.5241 38.7487 32.551 40.4721C29.4675 42.2727 25.9324 43.2586 22.3676 43.3878H21.2227C15.6 43.2884 10.07 40.8839 6.16397 36.8388C12.4988 36.8344 18.8325 36.8377 25.1673 36.8366C26.4877 36.8222 27.8269 36.9183 29.1241 36.608C31.3509 36.1212 33.3823 34.8074 34.7491 32.9847C36.0109 31.3231 36.7065 29.2443 36.6987 27.1577C36.7043 25.0987 36.0275 23.0463 34.7954 21.397C33.8945 20.1781 32.7011 19.1845 31.3509 18.4989C32.4108 17.2569 33.0334 15.6495 33.0577 14.0155C33.1052 12.1884 32.4074 10.3579 31.1577 9.02539C29.8605 7.61557 27.9748 6.76989 26.0583 6.75333C20.4731 6.74671 14.8879 6.75444 9.30267 6.74892C9.2828 8.32545 10.3338 9.83684 11.8165 10.3679C12.2449 10.5346 12.7041 10.5942 13.1601 10.6174C13.1634 13.1345 13.1623 15.6517 13.1612 18.1688C9.03881 17.4015 4.91974 16.6188 0.797363 15.8515C2.184 10.8305 5.46292 6.37024 9.82708 3.52962C13.1667 1.32932 17.1268 0.111597 21.1211 9.15527e-05Z" fill="#FFC42E"/>
      <path d="M17.9336 18.505C18.7925 15.894 19.6106 13.2698 20.496 10.6687C21.3538 13.2786 22.194 15.894 23.0451 18.5061C25.7986 18.5149 28.552 18.5028 31.3054 18.5116C29.1625 20.086 27.0074 21.6448 24.8535 23.2048C24.7796 23.281 24.583 23.3384 24.6625 23.4797C25.4983 26.0675 26.345 28.6531 27.1797 31.242C24.9452 29.6257 22.7206 27.9962 20.4849 26.3821C15.7046 29.862 10.9187 33.3341 6.13831 36.8128C5.48363 36.1405 4.87421 35.4251 4.31117 34.6744C7.84069 32.1616 11.3735 29.6555 14.9042 27.145C15.1448 26.9662 15.4175 26.8204 15.6085 26.582C15.4253 26.4859 15.2552 26.6228 15.0907 26.6824C10.9794 28.5493 6.86585 30.4129 2.7512 32.2709C2.26212 31.3988 1.83597 30.4924 1.47165 29.5617C5.7795 27.7014 10.1061 25.8864 14.4129 24.024C14.5266 23.939 14.8291 23.9379 14.6911 23.7115C9.99131 24.7173 5.31582 25.8334 0.620455 26.8602C0.389716 25.9151 0.22301 24.9547 0.121441 23.9886C4.64679 23.09 9.17103 22.1792 13.6975 21.2849C13.8454 21.2838 13.926 21.2087 13.9359 21.0586C9.29137 21.0498 4.64458 21.0376 0 21.0641V21.0354C0.0485765 20.1886 0.104881 19.3407 0.235155 18.5028C6.13499 18.4984 12.0337 18.5105 17.9336 18.505Z" fill="white"/>
      <path d="M71.995 11.0596C72.5724 10.9061 73.2204 10.9392 73.747 11.2373C74.4105 11.6204 74.8521 12.3678 74.8411 13.1373C74.8555 13.8748 74.4657 14.6023 73.8475 15.0031C73.3253 15.3332 72.664 15.384 72.0711 15.2493C71.136 15.0307 70.4736 14.0846 70.4957 13.1395C70.4803 12.2243 71.1007 11.308 71.995 11.0596Z" fill="white"/>
      <path d="M87.007 11.5529C88.3594 11.5507 89.7118 11.5507 91.0631 11.5529C91.0631 13.2906 91.0631 15.0295 91.0642 16.7672C92.3923 16.7716 93.7205 16.7617 95.0497 16.7727C97.07 16.8191 99.1511 17.3081 100.791 18.5358C102.174 19.5592 103.12 21.1369 103.398 22.8337C103.712 24.7271 103.451 26.7806 102.368 28.4002C101.379 29.9193 99.7517 30.9272 98.0261 31.4053C96.9188 31.7199 95.7618 31.8425 94.6125 31.838C92.0777 31.8369 89.5418 31.8392 87.007 31.8369C87.007 25.0749 87.007 18.3139 87.007 11.5529ZM91.0686 18.7952C91.0565 22.4661 91.0664 26.138 91.0631 29.8089C92.3217 29.8078 93.5802 29.8133 94.8388 29.8056C95.9881 29.789 97.1937 29.4015 97.9488 28.4951C98.9259 27.3392 99.1798 25.7616 99.1743 24.2921C99.1776 22.8216 98.9181 21.2417 97.9323 20.0925C97.1771 19.1982 95.9815 18.8162 94.8421 18.7997C93.5847 18.7919 92.3261 18.8008 91.0686 18.7952Z" fill="#FFC42E"/>
      <path d="M126.989 12.1322C128.34 12.13 129.692 12.1311 131.044 12.1311C131.046 13.6767 131.043 15.2223 131.045 16.768C132.542 16.768 134.039 16.7657 135.535 16.7691C135.535 17.4447 135.535 18.1193 135.535 18.7949C134.039 18.7982 132.542 18.796 131.046 18.796C131.044 21.3275 131.044 23.8579 131.045 26.3894C131.047 27.3875 131.217 28.4363 131.812 29.2643C132.271 29.9057 133.022 30.3131 133.806 30.3749C134.973 30.4665 136.113 30.0647 137.13 29.5237C137.421 30.1431 137.697 30.7701 137.998 31.3851V31.4016C135.751 32.5145 133.062 32.7474 130.681 31.936C129.127 31.4072 127.826 30.1519 127.325 28.5787C126.903 27.3775 126.996 26.0913 126.989 24.8416C126.988 22.8268 126.99 20.812 126.988 18.796C126.07 18.7949 125.153 18.7993 124.235 18.7938C124.236 18.1193 124.235 17.4436 124.236 16.7691C125.153 16.7657 126.07 16.7691 126.989 16.7669C126.99 15.2223 126.988 13.6767 126.989 12.1322Z" fill="#FFC42E"/>
      <path d="M108.543 18.2423C109.803 17.0941 111.466 16.4637 113.144 16.2683C115.185 16.0287 117.332 16.3014 119.151 17.2995C121.011 18.3129 122.389 20.1765 122.787 22.2598C123.021 23.4576 122.908 24.6831 122.934 25.8953C118.811 25.8964 114.687 25.8953 110.564 25.8964C110.746 27.1395 111.162 28.4456 112.154 29.2824C113.276 30.2297 114.821 30.4924 116.247 30.3566C117.856 30.2285 119.342 29.4657 120.61 28.5052C120.991 29.087 121.425 29.6545 121.753 30.2584C120.729 31.1659 119.433 31.7123 118.118 32.0446C116.06 32.5768 113.865 32.5271 111.817 31.9729C110.036 31.4893 108.36 30.4394 107.36 28.8607C106.225 27.134 105.973 24.9746 106.213 22.9597C106.422 21.1867 107.205 19.4479 108.543 18.2423ZM111.804 19.2856C110.78 20.5673 110.508 22.2697 110.477 23.8661C113.181 23.8672 115.885 23.8672 118.59 23.8672C118.594 22.1814 118.231 20.3576 117.024 19.1056C115.659 17.677 113.051 17.7267 111.804 19.2856Z" fill="#FFC42E"/>
      <path d="M50.65 11.5512C52.0013 11.5501 53.3526 11.5523 54.704 11.5501C54.7073 13.29 54.704 15.0288 54.7062 16.7676C57.0445 16.8306 59.4424 16.5402 61.7189 17.2148C63.4246 17.6961 65.0298 18.7008 66.009 20.2033C67.1086 21.845 67.3626 23.9338 67.0269 25.8503C66.741 27.4743 65.8423 28.9835 64.5341 29.9915C62.8626 31.2909 60.7043 31.7966 58.6199 31.8341C55.9636 31.8407 53.3063 31.8352 50.65 31.8363C50.6489 25.0753 50.65 18.3133 50.65 11.5512ZM54.7062 18.7957C54.704 22.4666 54.7062 26.1374 54.7062 29.8082C55.9747 29.806 57.2443 29.8138 58.5139 29.8049C59.6334 29.7806 60.8036 29.4075 61.5521 28.5386C62.5623 27.3816 62.8229 25.7775 62.8173 24.2893C62.8207 22.8254 62.5623 21.2532 61.5864 20.104C60.839 19.2108 59.6499 18.8222 58.515 18.8001C57.2454 18.7902 55.9758 18.799 54.7062 18.7957Z" fill="white"/>
      <path d="M78.8917 11.5513C80.2441 11.5513 81.5954 11.5513 82.9479 11.5513C82.9479 18.3133 82.9479 25.0754 82.9479 31.8364C81.5954 31.8375 80.2441 31.8375 78.8917 31.8364C78.8917 25.0754 78.8917 18.3133 78.8917 11.5513Z" fill="white"/>
      <path d="M70.6299 17.0676C71.9824 17.04 73.337 17.0643 74.6905 17.0565C74.6905 21.9826 74.6927 26.9098 74.6905 31.8371C73.3381 31.836 71.9868 31.8371 70.6355 31.836C70.631 26.9132 70.6421 21.9904 70.6299 17.0676Z" fill="white"/>
      <path d="M9.30194 6.74869C14.8871 6.75421 20.4723 6.74648 26.0575 6.7531C27.9741 6.76966 29.8597 7.61534 31.157 9.02516C32.4067 10.3577 33.1044 12.1882 33.057 14.0153C33.0327 15.6492 32.41 17.2567 31.3502 18.4987C32.7004 19.1843 33.8938 20.1779 34.7947 21.3967C36.0268 23.0461 36.7035 25.0985 36.698 27.1575C36.7057 29.244 36.0102 31.3229 34.7483 32.9844C33.3815 34.8072 31.3502 36.1209 29.1234 36.6078C27.8262 36.918 26.487 36.822 25.1666 36.8363C18.8318 36.8374 12.4981 36.8341 6.16323 36.8385C6.15661 36.8319 6.14336 36.8198 6.13673 36.8131C10.9171 33.3344 15.703 29.8623 20.4834 26.3824C22.719 27.9965 24.9436 29.626 27.1781 31.2423C26.3435 28.6534 25.4967 26.0678 24.661 23.48C24.5815 23.3387 24.778 23.2813 24.8519 23.2051C27.0059 21.6451 29.1609 20.0863 31.3038 18.5119C28.5504 18.5031 25.797 18.5152 23.0436 18.5064C22.1924 15.8943 21.3522 13.2789 20.4944 10.669C19.609 13.2701 18.7909 15.8943 17.932 18.5053C12.0322 18.5108 6.13342 18.4987 0.233582 18.5031C0.364959 17.6089 0.554849 16.7223 0.796628 15.8513C4.91901 16.6186 9.03808 17.4013 13.1605 18.1686C13.1616 15.6514 13.1627 13.1343 13.1594 10.6171C12.7034 10.594 12.2441 10.5343 11.8158 10.3676C10.3331 9.83661 9.28206 8.32522 9.30194 6.74869Z" fill="#393683"/>
      <path d="M0 21.0649C4.64458 21.0384 9.29137 21.0505 13.9359 21.0593C13.926 21.2095 13.8454 21.2846 13.6975 21.2857C9.17103 22.1799 4.64679 23.0907 0.121441 23.9894C0.0640327 23.486 0.0452645 22.9803 0 22.4758V21.0649Z" fill="#393683"/>
      <path d="M0.617676 26.8609C5.31304 25.8342 9.98853 24.718 14.6883 23.7123C14.8263 23.9386 14.5238 23.9397 14.4101 24.0247C10.1034 25.8872 5.77673 27.7022 1.46887 29.5624C1.12663 28.6814 0.84179 27.7784 0.617676 26.8609Z" fill="#393683"/>
      <path d="M15.0933 26.6824C15.2578 26.6227 15.4278 26.4858 15.6111 26.5819C15.4201 26.8204 15.1474 26.9661 14.9067 27.1449C11.3761 29.6555 7.84322 32.1616 4.31369 34.6743C3.74292 33.9081 3.21962 33.1055 2.75372 32.2709C6.86838 30.4128 10.9819 28.5492 15.0933 26.6824Z" fill="#393683"/>
    </svg>
  )
}

export default Logo
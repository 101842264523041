import React, { useEffect, useState } from 'react'
import AccordionItem, { IAccordionItemProps } from './AccordionItem'

import * as styles from './styles.module.scss'

export interface IAccordionProps {
  items: Omit<IAccordionItemProps, 'toggleExpanded'>[]
  isSingleExpand?: boolean
}

const Accordion = (props: React.PropsWithChildren<IAccordionProps>) => {
  const { items } = props

  const [expandedItem, setExpandedItem] = useState<number| null>(1)

  const handleExpand = (id: number | null) => setExpandedItem(id)

  return (
    <div className={styles.accordion}>
      {items.map((item) => {
        const active = item.id === expandedItem
        return (
          <AccordionItem key={item.id} isExpanded={active} toggleExpanded={() => handleExpand(!active ? item.id : null)} {...item} />
        )
      })}
    </div>
  )
}

export default Accordion

import React from "react";

const useActiveTab = (tabs: string[]) => {
  const [activeTab, setActiveTab] = React.useState(null)
  const [mappedTabs, setMappedTabs] = React.useState<any[]>([])
  const [offset, setOffset] = React.useState(0)

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      window.onscroll = () => {
        setOffset(window.scrollY)
      }
    }
  }, [])

  React.useEffect(() => {
    if (typeof window !== `undefined`) {
      const mapped = tabs.reduce<any>((prev, tab) => {
        const elem = document.getElementById(tab)
        return [ ...prev, { tab, nextOffset: elem ? elem.offsetTop + elem.offsetHeight :  0, offset: elem ? elem.offsetTop : 0 }]
      }, [])
      setMappedTabs(mapped)
    }
  }, [tabs])

  React.useEffect(() => {
    setActiveTab(mappedTabs.find(tab => tab.nextOffset > offset && tab.offset < offset))
  }, [offset, mappedTabs, setActiveTab])

  return { activeTab, offset }
}

export default useActiveTab
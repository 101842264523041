import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import useDeviceDetect from "../../hooks/useDeviceDetect"
import { AppleIcon, CmdIcon, WindowsIcon } from "../atoms/Icons"

const CurrentMirror = () => {
  const { t } = useTranslation()
  const { device, isMobile } = useDeviceDetect()

  const [url, setUrl] = React.useState('https://bilbet1.com')

  return (
    <div className={styles.currentMirror}>
      <div className={styles.title}>{t("currentMirror")}</div>

      <a className={styles.link} href={url} target="_blank" rel="noreferrer">{url}</a>

      {isMobile ? (
        <video className={styles.video} playsInline autoPlay muted loop>
          <source src="../../../video/current-mirror-mobile.mp4" type="video/mp4" />
        </video>
      ) : (
        <div className={styles.instruction}>
          <div className={styles.description}>{t("addToBookmark")}</div>
          <div className={styles.frame}>
            {device === "Mac" ? <AppleIcon /> : <WindowsIcon />}
            <div className={styles.combination}>
              <div className={styles.key}>{device === "Mac" ? (<><CmdIcon /> Cmd</>) : "Ctrl"}</div>
              +
              <div className={styles.key}>D</div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default CurrentMirror
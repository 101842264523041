import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import Accordion from "../Accordion"


const faqItems = [
  {
    id: 1,
    title: 'question1',
    description: 'answer1',
  },
  {
    id: 2,
    title: 'question2',
    description: 'answer2'
  },
  {
    id: 3,
    title: 'question3',
    description: 'answer3'
  },
  {
    id: 4,
    title: 'question4',
    description: 'answer4'
  },
]


const FAQ = () => {
  const { t } = useTranslation()

  return (
    <div className={styles.faq}>
      <div className={styles.title}>{t("faq")}</div>

      <div className={styles.container}>
        <Accordion items={faqItems} />
      </div>
    </div>
  )
}

export default FAQ
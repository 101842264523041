import classNames from "classnames"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { useRef, useState } from "react"
import useOutsideAlerter from "../../../hooks/useClickOutside"
import { UzFlag, USFlag, RusFlag, HiFlag, BnFlag, ArrowDownIcon } from "../Icons"

import * as styles from './styles.module.scss'

const languages = [
  {
    code: 'en',
    icon: <USFlag />
  },
  {
    code: 'uz',
    icon: <UzFlag />
  },
  {
    code: 'ru',
    icon: <RusFlag />
  },
  {
    code: 'hi',
    icon: <HiFlag />
  },
  {
    code: 'bn',
    icon: <BnFlag />
  }
]

const LanguagePicker = () => {
  const {language, changeLanguage} = useI18next()

  const [open, setOpen] = useState<boolean>(false)
  const [currentLanguage, setCurrentLanguage] = useState<typeof languages[0]>()

  const wrapperRef = useRef(null)
  const btnRef = useRef(null)
  useOutsideAlerter(wrapperRef, btnRef, () => setOpen(false))

  React.useEffect(() => {
    setCurrentLanguage(languages.find(lng => lng.code === language) || languages[0])
  }, [language])

  return (
    <div className={styles.languagePicker}>
      <button 
        className={classNames(styles.currentLanguage, { [styles.open]: open })}
        ref={btnRef}
        onClick={() => setOpen(!open)}>
          <div className={styles.lang}>
            {currentLanguage?.icon}
            {currentLanguage?.code}
          </div>
          <ArrowDownIcon />
      </button>
      {open && (<ul className={styles.languages} ref={wrapperRef}>
        {languages.map(lng => (
          <a
            className={classNames(styles.language, { [styles.active]: lng.code === language })}
            onClick={() => {
              changeLanguage(lng.code)
            }}>
              {lng.icon}
              {lng.code}
          </a>
        ))}
      </ul>)}
    </div>
  )
}

export default LanguagePicker
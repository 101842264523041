// extracted by mini-css-extract-plugin
export var card = "styles-module--card--3565c";
export var cards = "styles-module--cards--5183d";
export var container = "styles-module--container--c1b4e";
export var content = "styles-module--content--7108d";
export var description = "styles-module--description--f9824";
export var download = "styles-module--download--dface";
export var extension = "styles-module--extension--fe84f";
export var icon = "styles-module--icon--82ef6";
export var imageContainer = "styles-module--imageContainer--345bd";
export var link = "styles-module--link--39616";
export var name = "styles-module--name--5db3e";
export var texts = "styles-module--texts--d4113";
export var title = "styles-module--title--1a557";
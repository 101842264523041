import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'
import classNames from "classnames"
import Link from "../Link"

export interface ITabsProps {
  tabs: string[]
  activeTab?: any
}
const Tabs = ({ tabs, activeTab }: ITabsProps) => {
  const { t } = useTranslation()

  const scrRef = React.useRef<HTMLDivElement>(null)

  React.useEffect(() => {
    if (scrRef.current && activeTab?.tab) {
      switch(activeTab?.tab) {
        case 'androidApp':
          scrRef.current.scrollLeft = 0
          break
        case 'currentMirror':
          scrRef.current.scrollLeft = 50
          break
        case 'telegramBot':
          scrRef.current.scrollLeft = 130
          break
        case 'rebrandly':
          scrRef.current.scrollLeft = 180
          break
        case 'VPN':
          scrRef.current.scrollLeft = 250
          break
        case 'secureBrowsers':
          scrRef.current.scrollLeft = 350
          break
        default:
          scrRef.current.scrollLeft = 0
          break
      }
    }
  }, [scrRef.current, activeTab?.tab])

  return (
    <div className={styles.wrapper} ref={scrRef}>
      <div className={styles.tabs} >
        {tabs.map(tab => (
          <Link
            key={tab}
            to={`#${tab}`}
            active={activeTab?.tab === tab}
          >
            {t(tab)}
          </Link>
        ))}
      </div>
    </div>
  )
}

export default Tabs
import { useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { FBIcon, InstagramIcon, TelegramIcon, TwitterIcon } from "../atoms/Icons"
import LangPicker from "../atoms/LangPicker"
import Logo from "../atoms/Logo"

import * as styles from './styles.module.scss'
import Tabs from "../atoms/Tabs"
import useDeviceDetect from "../../hooks/useDeviceDetect"
import useActiveTab from "../../hooks/useActiveTab"
import classNames from "classnames"

const Header = ({ tabs }: any) => {
  const { t } = useTranslation()
  const { isMobile, device } = useDeviceDetect()
  const { activeTab, offset } = useActiveTab(tabs)

  return (
    <>
      {offset < (isMobile ? 270 : 320) ? (
        <div className={classNames(styles.header, styles[device])}>
          <div className={styles.container}>
            <Logo />
            <div className={styles.controls}>
              <div className={styles.socials}>
                <a href={t("instagram")} target="_blank" className={styles.socialLink}><InstagramIcon /></a>
                <a href={t("telegram")} target="_blank" className={styles.socialLink}><TelegramIcon /></a>
                <a href={t("facebook")} target="_blank" className={styles.socialLink}><FBIcon /></a>
                {/* <a href={t("twitter")} target="_blank" className={styles.socialLink}><TwitterIcon /></a> */}
              </div>
              <LangPicker />
            </div>
          </div>
        </div>
      ) : (
        <div className={styles.tabHeader}>
          <Tabs tabs={tabs} activeTab={activeTab} />
        </div>
      )}
    </>
  )
}

export default Header
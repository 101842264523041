import { Trans, useTranslation } from "gatsby-plugin-react-i18next"
import * as React from "react"
import { FBIcon, InstagramIcon, TelegramIcon, TwitterIcon } from "../atoms/Icons"

import * as styles from './styles.module.scss'
import Logo from "../atoms/Logo"

const Footer = () => {
  const { t } = useTranslation()

  return (
    <>
      <div className={styles.footerInfo}>
        <div className={styles.content}>
          <div className={styles.text}>
            <p className={styles.text} dangerouslySetInnerHTML={{ __html: t("footerInfoText") }}></p>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        <div className={styles.container}>
          <Logo />
          <div className={styles.copiright}>
            Copyright {new Date().getFullYear()}
          </div>
          <div className={styles.socials}>
            <a href={t("instagram")} target="_blank" className={styles.socialLink}><InstagramIcon /></a>
            <a href={t("telegram")} target="_blank" className={styles.socialLink}><TelegramIcon /></a>
            <a href={t("facebook")} target="_blank" className={styles.socialLink}><FBIcon /></a>
            {/* <a href={t("twitter")} target="_blank" className={styles.socialLink}><TwitterIcon /></a> */}
          </div>
        </div>
      </footer>
    </>
  )
}

export default Footer
import classNames from "classnames"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import { useI18next } from "gatsby-plugin-react-i18next"
import * as React from "react"

import * as styles from './styles.module.scss'

interface IProps {
  to: string
  className?: string
  children: any
  active?: boolean
  onClick?: () => void
}
const Link = ({ children, to, className, active = false, onClick }: IProps) => {
  const {language} = useI18next()

  return (
    <AnchorLink to={language !== 'en' ? `${language}/${to}` : to} onAnchorLinkClick={onClick} className={classNames(styles.link, { [styles.active]: active }, className)}>{children}</AnchorLink>
  )
}

export default Link